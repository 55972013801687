html,
body,
#root-container {
  height: 100%;
  min-width: 1280px;
}

#root-container {
  display: flex;
  flex-direction: column;
  padding: 0;
}

/* allow click events to trigger on disabled inputs */
input[disabled] {
  pointer-events: none;
}

a.MuiButton-text.current-page {
  background-color: #55d3f2;
}

header {
  flex: 0 0 auto;
}

footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-left: 1vh;
  padding-right: 1vh;
  justify-content: flex-start;
  bottom: 0;
  left: 0;
  height: 1.5em;
  width: 100%;
}

#main-container {
  min-height: 500px;
  flex: 1 0 auto;
}

.additional-questions {
  margin: 0.5%;
}

.cross-ref-input-box {
  display: inline-flex;
  padding: 0.5%;
  margin-right: 1%;
  align-items: flex-start;
  flex-basis: fit-content;
  justify-content: space-between;
}

.cross-ref-input-box > label {
  margin-top: 1.4em;
}

#cross-ref-toolbar {
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}

.case-item-label {
  margin-right: 0.25em;
}
